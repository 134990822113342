export class APIs {
  // Identity

  public static Identity = {
    LoginUaePass: 'Identity/api/UaePass/get-auth-code-url',
    GetLoginToken: 'Identity/api/UaePass/login',
    LogoutUaePass: 'Identity/api/UaePass/get-logout-url',
    RefreshToken: 'Identity/api/Identity/RefreshToken',
    RegisterUaePass: 'Identity/api/UaePass/get-auth-register-code-url',
    postConfirm: 'Identity/api/UaePass/confirm-register',
  };

  // Translation
  public static Translation = {
    Get: '/Utilities/api/CMS/Resource/Get',
    GetUnAuth: '/Utilities/api/CMS/Resource/GetUnAuthorizedResources',
    Set: '/Utilities/api/CMS/Resource/Set',
    createTranslation: '/Utilities/api/Translator/Translate',
  };
  // Translation
  public static Complaint = {
    GetList: 'Complaints/GetList',
    Create: 'HHEO/api/Complaints/Create',
    GetLockups: 'HHEO/api/Complaints/GetLookups',
  };

  public static Home = {
    SliderData: '/HHEO/api/Slider/Get',
    GetCategories: 'HHEO/api/Organization/Categories/Get',
    GetServices: 'HHEO/api/Service/Get',
    GetTargetAudiences: '/HHEO/api/Lookup/Get/TargetAudience',
    GetServiceTypes: '/HHEO/api/Lookup/Get/ServiceType',
    GetOrganizationsList: '/HHEO/api/Organization/GetList',
    GetStatusList: '/HHEO/api/Lookup/ExternalRequestStatus',
    GetEventCalender: 'EventService/api/public/event/filter-with-month',
  };

  public static Contact = {
    Categories: 'HHEO/api/Organization/Categories/Get',
    CategoryById: 'HHEO/api/Organization/GetByCategoryId',
    SubmitInquiry: 'Utilities/api/public/general-questions/submit-inquiry',
    MapData: 'Utilities/api/public/setting/map'
  };
  public static Service = {
    ServiceById: 'HHEO/api/Service/GetDetails',
    GetBookingDetailsAppointments: 'HHEO/api/my/requests/appointments/',
    ApproveAppointments: 'HHEO/api/my/requests/appointments/confirm',
    RejectAppointments: 'HHEO/api/my/requests/appointments/reject',
    RequestRating: 'HHEO/api/my/requests/rate/',
    SubmitRequestRating: 'HHEO/api/my/requests/rate/submit',
  };

  public static Suggestion = {
    GetLockups: '/HHEO/api/Suggestion/Get/Lookup',
    Create: '/HHEO/api/Suggestion/Create',
  };
  public static Profile = {
    ProfileById: 'UserManagement/api/Profile/Get',
    GetUserRequests: 'HHEO/api/Service/Request/Get',
    GetUserSuggestions: 'HHEO/api/my/suggestions',
    GetUserComplaints: 'HHEO/api/my/complaints',
    GetUserComplaintById: 'HHEO/api/my/complaints/',
    GetUserSuggestionsById: 'HHEO/api/my/suggestions/',
    GetUserRequestById: 'HHEO/api/my/requests/',
    CreateRequest: 'HHEO/api/Service/Request/Create',
    getLookUp: 'HHEO/api/Service/',
    GetRequestDetails: 'HHEO/api/my/requests/',
    UpdateRequest: 'HHEO/api/my/requests/',
  };

  public static UploadAttachment = {
    Upload: 'FileService/api/File/upload',
    UploadToBlob: 'FileService/api/File/upload-to-blob',
  };

  public static Files = {
    GetFiles: 'FileService/api/File/GetFiles',
  };
  public static Events = {
    GetEventsWithFilter: 'eventservice/api/public/event/filter',
    FilterEventsWithMonth: 'eventservice/api/public/event/filter-with-month',
    GetCategories: 'eventservice/api/public/event/categories',
    GetEventDetails: 'EventService/api/public/event/details/',
    GetEventRegisterationDetails: 'eventservice/api/event/details/',
    RegisterEvent: 'eventservice/api/event/register',
    AttendeeRating: 'eventservice/api/Attendee/Rating',
    GetUserEvents: 'EventService/api/my/attendees',
    GetEventTypes: 'eventservice/api/Lookup/event-lookups',
    // RegisteredEvent: 'EventService/api/Attendee/GetRegisterationDetails'
    RegisteredEvent: 'EventService/api/my/attendees',
    CancelRegistration: 'EventService/api/Attendee/Cancel',
    AttachmentsRegisteredEvent: 'FileService/api/File/GetFile/',
    UpdateRegistration: 'EventService/api/Attendee/re-submit',
    RegisterEventStatus: 'EventService/api/Lookup/attendee-status',
  };

  public static Rating = {
    PostRating: 'EventService/api/Attendee/Rating',
  };

  public static Utilities = {
    AboutUs: 'Utilities/api/public/cms/page/HHEOPortal:AboutUs',
    SpeechOfHerHighness:
      'Utilities/api/public/cms/page/HHEOPortal:SpeechOfHerHighness',
    BiographyofHerHighnessSheikha:
      'Utilities/api/public/cms/page/HHEOPortal:BiographyofHerHighnessSheikha',
    Categories: 'Utilities/api/public/cms/page/HHEOPortal:Quotes',
    Achievement: 'Utilities/api/public/cms/page/HHEOPortal:Achievements',
    Quotes: 'Utilities/api/public/cms/page/HHEOPortal:Quotes',
    ChildrenAndYouthEntity:
      'Utilities/api/public/cms/page/HHEOPortal:ChildrenAndYouthEntity',
    FamilyEntity: 'Utilities/api/public/cms/page/HHEOPortal:FamilyEntity',
    WomenEmpowermentEntity:
      'Utilities/api/public/cms/page/HHEOPortal:WomenEmpowermentEntity',
    CharityAndGivingEntity:
      'Utilities/api/public/cms/page/HHEOPortal:CharityAndGivingEntity',
    TestList: 'Utilities/api/public/cms/page/HHEOPortal:TestList',
    TestPage: 'Utilities/api/public/cms/page/HHEOPortal:TestPage',
    GetChildrenByParentId: 'Utilities/api/public/cms/elements-by-parent-id',
    editItem: 'Utilities/api/cms/page-element/set',
    organization: 'Utilities/api/public/cms/page/',
    awardsAndAchievements:
      'Utilities/api/public/cms/page/HHEOPortal:Achievement',
    addItem: 'Utilities/api/cms/page-element/add-list-item',
    deleteItem: 'Utilities/api/cms/page-element/delete',
  };
  public static Jobs = {
    GetJobsWithFilter: 'CareersAndVolunteers/api/public/careers/jobs/list',
    GetJobDetails: 'CareersAndVolunteers/api/public/careers/jobs/details',
    GetDegreeLookUps: 'CareersAndVolunteers/api/careers/lookups/degree-type',
    GetProficiencyLevelLookUps:
      'CareersAndVolunteers/api/careers/lookups/proficiency-level',
    GetSkillLookUps: 'CareersAndVolunteers/api/careers/lookups/skill',
    ApplyInJob: 'CareersAndVolunteers/api/careers/jobs/apply',
    UpdateApplication:
      'CareersAndVolunteers/api/careers/jobs/applications/update',
    GetJobTypeLookUps:
      'CareersAndVolunteers/api/public/careers/lookups/job-type',
    GetWorkEnvLookUps:
      'CareersAndVolunteers/api/careers/lookups/work-environment',
    GetJobsLookups: 'CareersAndVolunteers/api/admin/careers/jobs/lookups',
    GetExperienceLevelLookUp:
      'CareersAndVolunteers/api/public/careers/lookups/experience-level',
    GetCurrencyLookUp:
      'CareersAndVolunteers/api/public/careers/lookups/currency',
    GetJobsQuestions: 'CareersAndVolunteers/api/careers/jobs/questions/',
    GetUserJobs: 'CareersAndVolunteers/api/my/job-applications',
    GetMaxSalary:
      'CareersAndVolunteers/api/public/careers/lookups/salary-range',
    RegisterJobStatus: 'CareersAndVolunteers/api/careers/lookups/job-status',
    JobApplicationStatus:
      'CareersAndVolunteers/api/careers/lookups/job-application-status',
  };
  public static Volunteers = {
    GetVolunteersWithFilter: 'CareersAndVolunteers/api/public/volunteers/list',
    GetVolunteerStatus:
      'CareersAndVolunteers/api/volunteers/lookups/volunteer-status',
    GetVolunteerDetails: 'CareersAndVolunteers/api/public/volunteers/details',
    ApplyInVolunteer: 'CareersAndVolunteers/api/volunteers/apply',
    UpdateApplication:
      'CareersAndVolunteers/api/volunteers/applications/update',
    RegisterVolunteerStatus: 'api/volunteers/lookups/volunteer-status',
    VolunteerApplicationStatus:
      'CareersAndVolunteers/api/volunteers/lookups/volunteer-application-status',
    GetVolunteersQuestions: 'CareersAndVolunteers/api/volunteers/questions/',
    GetUserVolunteers: 'CareersAndVolunteers/api/my/volunteer-applications',
  };

  public static Memberships = {
    GetMemberships: 'Membership/api/public/memberships/packages/list',
    GetMyMemberships: 'Membership/api/my/memberships',
    GetMyApplication: 'Membership/api/my/application/',
    GetMyMembershipDetails: 'Membership/api/my/memberships/packages/',
    GetDurationlookups: 'Membership/api/public/lookups/durations',
    GetPackageTypelookups: 'Membership/api/public/lookups/package-type',
    //GetPackageDetails: 'Membership/api/public/memberships/packages/details',
    GetPackageDetails: 'Membership/api/public/memberships/packages/details/',
    GetPaymentDetails: 'Payment/api/Payment/details/',
    GetRequestDetails: 'Membership/api/my/requests/',
    GetApplicationFormFields: 'Membership/api/memberships/packages/fields/',
    PackageApply: 'Membership/api/memberships/packages/apply',
    GetStatusStatus: 'Membership/api/public/lookups/memberShip-status',
    GetMyMemberShipList: 'Membership/api/my/requests',
    GetMyActiveMemberShipList: 'Membership/api/my/requests/active',
    GetDraftApplicationFormFields: (id: string): string =>
      `Membership/api/my/requests/${id}/application-data`,
    PostDraftApplication: 'Membership/api/memberships/requests/update',
    CancelRequest: 'Membership/api/memberships/requests/cancel',
    GetInvoice: 'Membership/api/payment/membership/invoice/',
    GetTransferMembership: 'Membership/api/members/transfer/request-details/',
    ApproveTransferMembership: 'Membership/api/members/transfer/approve',
    RejectTransferMembership: 'Membership/api/members/transfer/reject',
  };

  public static Bookings = {
    BookingsList: 'Booking/api/public/assets/list',
    SubmitBooking: 'Booking/api/assets/submit',
    LookUps: 'Booking/api/booking/lookups',
    MyBookingsLookUps: 'Booking/api/lookups',
    MyBookingsList: 'Booking/my/bookings',
    MyBookingDetails: 'Booking/my/bookings/',
    getBookingDetails: 'Booking/api/public/assets/details/',
    cancelBooking: 'Booking/api/booking/cancel',
    updateBooking: 'Booking/api/booking/update',
    BookingsAssetTypes: 'Booking/api/public/lookups/asset-types',
    BookingsAssetCapacityRanges:'Booking/api/public/lookups/asset-capacity-ranges'
  };

  public static Competitions = {
    GetCompetitions: 'Competitions/api/public/competitions/list',
    GetCompetitionDetails: 'Competitions/api/public/competitions/details/',
    GetUserCompetitions: 'Competitions/my/competitions',
    GetCompetitionsStatus: 'Competitions/api/public/lookups/competition-status',
    GetCompetition: 'Competitions/api/public/competitions/details/',
    GetCompetitionSkills: 'Competitions/api/public/lookups/skills',
    CompetitionApply: 'Competitions/api/Competitions/apply',
    CompetitionType: 'Competitions/api/public/lookups/competition-type',
    MyCompetitionDetails: 'Competitions/my/competitions/',
  };

  public static MediaCenter = {
    AlbumDetails: 'Utilities/api/public/media-center/albums/details',
    NewsDetails: 'Utilities/api/public/press-releases/details',
    albumsList : 'Utilities/api/public/media-center/albums/list',
    videosList : 'Utilities/api/public/media-center/videos/list',
    pressList : 'Utilities/api/public/press-releases/list',
    pressCategories : 'Utilities/api/public/press-releases/categories/list'
  }
}
